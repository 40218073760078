<template>
  <div id="customer-list">
    <v-card>
      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(22)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.new') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="listTable"
        item-key="id"
        :options.sync="options"
        :loading="loading"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page')}"
      >
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolveStatusText(item.status) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="hasPermission(21)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.show') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(23)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="success"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onEdit(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.edit') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(24)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="error"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onDelete(item.id)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiEyeOutline,
} from '@mdi/js'
import { computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'

import { deleteClient } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

import useCustomerList from './useCustomerList'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { t, tInContext } = useUtils()
    const { router, route } = useRouter()
    const {
      listTable,
      computedTableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      filters,
      totalLocal,

      fetchData,
      resolveStatusVariant,
      resolveStatusText,
      hasPermission,
      setFilters,
      setOptions,
      getFilterByModule,
    } = useCustomerList()

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const onCreate = () => {
      router.push({
        name: 'views-client-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onEdit = item => {
      router.push({
        name: 'views-client-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 3,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onShow = item => {
      router.push({
        name: 'views-client-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 2,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onDelete = async id => {
      Swal.fire({
        title: tInContext('questions.are_you_sure', vm),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `${tInContext('questions.yes', vm)} ${tInContext('tooltip.delete', vm)}!`,
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deleteClient(id)
          if (response.ok) {
            success(response.message)
            fetchData()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      const filtersStored = getFilterByModule('views-client-list')
      if (filtersStored.search) searchQuery.value = filtersStored.search

      await fetchData()
    })

    return {
      listTable,
      computedTableColumns,
      searchQuery,
      totalListTable,
      statusOptions,
      loading,
      options,
      filters,
      totalLocal,
      onCreate,
      onEdit,
      onShow,
      onDelete,
      avatarText,
      resolveStatusVariant,
      resolveStatusText,
      fetchData,
      hasPermission,
      setFilters,
      setOptions,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
